@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.content p > img {
  width: 100%;
  margin: auto;
}

.content a {
  text-decoration: underline;
}

.content blockquote {
  margin-top: 41px;
  margin-bottom: 34px;
  /* margin-left: 20px; */
  font-size: 16px;
  font-weight: 400;
  border-left: 5px solid rgb(238, 238, 238);
  font-style: italic;
  padding: 0 20px;
}

* {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

